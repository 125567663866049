import { Helmet } from "react-helmet";

type SocialCardProps = {
  /** The title of the NFT */
  title?: string;
  /** The description of the NFT collection */
  description?: string;
  /** The URL image of the NFT */
  image?: string;
};

export const SocialCard = (props: SocialCardProps) => {
  const {
    title = "KISS Gives | Simple Donations",
    description = "Moving Purpose Forward",
    image = "https://i.ibb.co/8dWLY8H/2.png",
  } = props;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://kissgives.com" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};
