import axios from "axios";
import { buildAuthHeaders } from "../buildAuthHeaders";
import { rootUrl } from "../rootUrl";

export type AnalyticsAmount = {
  createdAt: Date;
  amount: number;
};

export type AnalyticsContact = {
  createdAt: Date;
};

export type AnalyticsCampaign = {
  id: string;
  name: string;
};

export type GetAnalyticsResponse = {
  amounts: AnalyticsAmount[];
  campaigns: AnalyticsCampaign[];
  contacts: AnalyticsContact[];
};

const endpoint = `/user/analytics`;

export const getAnalytics = async (
  accessToken: string,
  campaignId?: string
): Promise<GetAnalyticsResponse> => {
  const { data } = await axios.get<GetAnalyticsResponse>(
    `${rootUrl}${endpoint}${campaignId ? `?campaignId=${campaignId}` : ""}`,
    buildAuthHeaders(accessToken)
  );
  return data;
};
