import { keyframes, styled, css } from "styled-components";
import { colors } from "../colors";

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Image = styled.img<{ $fadeIn?: boolean; size?: string }>`
  width: ${(props) => props.size ?? "4.75rem"};
  height: ${(props) => props.size ?? "4.75rem"};
  animation: ${(props) =>
    props.$fadeIn
      ? css`
          ${fade} 3s ease-in-out
        `
      : undefined};
`;

export const HeaderText = styled.h1`
  color: ${colors.black};
  font-size: 2rem;
  font-weight: 800;
  margin: 0 0 0.25rem 0;
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const SubTitle = styled.p`
  font-size: 1.75rem;
  font-weight: 500;
  color: ${colors.black + "50"};
  animation: ${fadeIn} 3s ease-in-out;
  width: 100%;
  margin: 0.5rem 0;
`;

export const Gold = styled.span`
  color: ${colors.gold};
  font-weight: 700;
`;

export const StyledChartContainer = styled.div`
  max-width: 80%;
  min-width: 40rem;
  margin: 1rem 0rem;
  padding: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  border-radius: 0.5rem;
  border: 1px solid ${colors.black + "10"};
`;

export const ChartFilterSelector = styled.div<{ $color: string }>`
  margin-top: 1rem;
  select {
    font-size: 0.85rem;
    padding: 0.25rem 1rem;
    border-radius: 4px;
    border: 1px solid ${(props) => props.$color};
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &:focus {
      outline: none;
      border-color: ${(props) => props.$color};
    }
  }
`;

export const SpinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
