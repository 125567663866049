import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import { Show } from "../components/Show";

type StepDetail = {
  title: string;
  description?: string;
};

export type StepperProps = {
  steps: StepDetail[];
  index: number;
};

export const useStepper = (props: StepperProps) => {
  const { steps, index } = props;
  const { activeStep, setActiveStep } = useSteps({
    index,
    count: steps.length,
  });

  const stepper = (
    <Stepper index={activeStep} colorScheme="green">
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink="0">
            <StepTitle>{step.title}</StepTitle>
            <Show when={!!step.description}>
              <StepDescription>{step.description}</StepDescription>
            </Show>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );

  return { stepper, setActiveStep, activeStep };
};
