import { Spinner, Text } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { Sidebar } from "../components/dashboard/Sidebar";
import { ConnectBank } from "../components/dashboard/Start";
import styled from "styled-components";
import { Analytics } from "../components/dashboard/Analytics";
import { Settings } from "../components/dashboard/Settings";
import { Welcome } from "../components/dashboard/Welcome";
import { useAuth } from "../hooks/useAuth";
import { Show } from "../components/Show";
import { useEffect } from "react";
import { colors } from "../colors";
import { SpinWrapper } from "../components/Reusable";

const Container = styled.div`
  display: flex;
  background-color: ${colors.gold + 10};
`;

const ContentContainer = styled.div`
  padding: 1rem 3rem;
  width: 100%;
  overflow-y: scroll;
  max-height: 100vh;
`;

export const Dashboard = () => {
  const { onboardingResults, getOnboardingResults, accessToken } = useAuth();
  useEffect(() => {
    if (onboardingResults.organization) return;
    (async () => {
      accessToken && (await getOnboardingResults(accessToken));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingResults]);
  return (
    <Container>
      <Sidebar />
      <ContentContainer>
        <Show
          when={!!onboardingResults.organization}
          whenFalseContent={
            <SpinWrapper>
              <Spinner />
            </SpinWrapper>
          }
        >
          <Text fontSize="2xl">{onboardingResults.organization}</Text>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="start" element={<ConnectBank />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="settings" element={<Settings />} />
          </Routes>
        </Show>
      </ContentContainer>
    </Container>
  );
};
