import axios from "axios";
import { rootUrl } from "../rootUrl";

export type PostCheckoutSessionRequest = {
  campaignId: string;
  amount: number;
  isMonthly: boolean;
  isCoveringFees: boolean;
  tipAmount: number;
  isAnon: boolean;
};

export const getCheckoutSession = async (
  checkout: PostCheckoutSessionRequest
): Promise<string> => {
  const endpoint = `/pay`;
  const { data } = await axios.post<string>(`${rootUrl}${endpoint}`, checkout);
  return data;
};
