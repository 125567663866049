import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ModalProvider } from "./contexts/ModalContext";
import { Landing } from "./pages/Landing";
import { TermsOfService } from "./pages/TermsOfService";
import { SmsPolicy } from "./pages/SmsPolicy";
import { Campaign } from "./pages/Campaign";
import { Dashboard } from "./pages/Dashboard";

localStorage.setItem("onboarding", "false");

export const App = () => {
  return (
    <ChakraProvider>
      <ModalProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/sms-policy" element={<SmsPolicy />} />
            {/* Setup Dashboard as a parent route */}
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/campaign/:id" element={<Campaign />} />
          </Routes>
        </Router>
      </ModalProvider>
    </ChakraProvider>
  );
};
