import { NavLink } from "react-router-dom";
import { VStack, Text, Divider } from "@chakra-ui/react";
import { colors } from "../../colors";
import { Image } from "../Reusable";
import icon from "../../assets/icon.svg";
import styled from "styled-components";
import { STRIPE_DASHBOARD } from "../../constants";
import { useAuth } from "../../hooks/useAuth";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  width: 20%;
  min-width: 15rem;
  max-width: 18rem;
  height: 100vh;
  padding: 1.25rem;
  background-color: ${colors.black};
`;

const NavItem = styled(NavLink)`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0.5rem;
  text-decoration: none;

  &.active {
    color: ${colors.gold};
  }

  &:hover {
    color: ${colors.gold};
  }
`;

const StyledText = styled.p`
  color: ${colors.white + "90"};
  font-weight: 500;
  padding: 0 0.5rem;
  cursor: pointer;
`;

const ExternalLink = styled.a`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0.5rem;
  text-decoration: none;

  &:hover {
    color: ${colors.gold};
  }
`;

export const Sidebar = () => {
  const { signOut } = useAuth();
  return (
    <Container>
      <VStack align="stretch" spacing={4}>
        <HeaderContainer>
          <Image $fadeIn src={icon} size="2.25rem" />
          <Text ml="1" fontSize="xl" fontWeight="bold" color={colors.white}>
            KISS Gives
          </Text>
        </HeaderContainer>
        <Divider />
        <NavItem to="/dashboard/start" end>
          Get Started
        </NavItem>
        <ExternalLink
          href={STRIPE_DASHBOARD}
          target="_blank"
          rel="noopener noreferrer"
        >
          Payments
        </ExternalLink>
        <NavItem to="/dashboard/analytics" end>
          Analytics
        </NavItem>
        <NavItem to="/dashboard/settings" end>
          Settings
        </NavItem>
        <Divider />
        <StyledText onClick={signOut}>Sign Out</StyledText>
      </VStack>
    </Container>
  );
};
