import { Box, keyframes } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { colors } from "../colors";
import { getRandomEmoji } from "../utils/helpers";

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

export type RecentDonationsTickerProps = {
  messages: string[];
  themeColor?: string;
};

export const RecentDonationsTicker = (props: RecentDonationsTickerProps) => {
  const { messages, themeColor } = props;
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3500);

    return () => clearInterval(timer);
  }, [messages.length]);

  return (
    <Box w="100%" overflow="hidden" bg="transparent">
      <Box
        key={index}
        display="inline-block"
        animation={`${slideIn} 2s ease forwards, ${slideOut} 2s 2s ease forwards`}
        fontWeight={700}
        color={themeColor ?? colors.gold}
      >
        {messages.length && `${messages[index]} ${getRandomEmoji()}`}
      </Box>
    </Box>
  );
};
