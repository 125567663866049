import axios from "axios";
import { buildAuthHeaders } from "../buildAuthHeaders";
import { rootUrl } from "../rootUrl";

type PaymentInterval = "ONE_TIME" | "MONTHLY";

type GetPaymentLinksResponse = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  clientId: string;
  stripeCustomerId: string;
  paymentLink: string;
  amount: number;
  paymentInterval: PaymentInterval;
}[];

const endpoint = `/user/get-payment-links`;

export const getPaymentLinks = async (
  accessToken: string
): Promise<GetPaymentLinksResponse> => {
  const { data } = await axios.get<GetPaymentLinksResponse>(
    `${rootUrl}${endpoint}`,
    buildAuthHeaders(accessToken)
  );
  return data;
};
