import { useEffect, useState } from "react";
import { Show } from "./Show";

interface CountdownTimerProps {
  expirationUtc: string;
  colorTheme: string;
  isInAlertMessage?: boolean;
}

export const CountdownTimer = (props: CountdownTimerProps) => {
  const { expirationUtc, colorTheme, isInAlertMessage } = props;
  const [timeLeft, setTimeLeft] = useState<string>("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const expirationTime = new Date(expirationUtc).getTime();
      const difference = expirationTime - now;

      if (difference > 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeLeft("Expired!");
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [expirationUtc]);

  return (
    <Show when={timeLeft !== "Expired!"}>
      <p
        style={{
          color: colorTheme,
          fontWeight: 700,
          fontSize: isInAlertMessage ? "1rem" : "1.5rem",
        }}
      >
        {`⏱ ${timeLeft}`}
      </p>
    </Show>
  );
};
