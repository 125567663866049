import { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { colors } from "../../../colors";
import { ChartFilterSelector, StyledChartContainer } from "../../Reusable";
import { AnalyticsContact } from "../../../api/user/getAnalytics";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface AmountChartProps {
  data: AnalyticsContact[];
}

type Aggregation = {
  [key: string]: number;
};

export const ReachChart = (props: AmountChartProps) => {
  const { data } = props;
  const [timeFrame, setTimeFrame] = useState("day");

  const processData = (
    data: AnalyticsContact[],
    timeFrame: string
  ): { labels: string[]; data: number[] } => {
    const groupByTimeFrame = (date: Date): string => {
      switch (timeFrame) {
        case "day":
          return date.toLocaleDateString();
        case "month":
          return `${date.getFullYear()}-${date.getMonth() + 1}`;
        case "year":
          return date.getFullYear().toString();
        default:
          return date.toLocaleDateString();
      }
    };

    const aggregation = data.reduce((acc: Aggregation, { createdAt }) => {
      const date = new Date(createdAt);
      const key = groupByTimeFrame(date);
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {} as Aggregation);

    // Sort the keys (dates) to ensure the order is correct for cumulative calculation
    const sortedKeys = Object.keys(aggregation).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );

    // Calculate cumulative sums
    const cumulativeData = sortedKeys.reduce(
      (acc: number[], key: string, index: number) => {
        if (index === 0) {
          acc.push(aggregation[key]);
        } else {
          acc.push(acc[index - 1] + aggregation[key]);
        }
        return acc;
      },
      []
    );

    return { labels: sortedKeys, data: cumulativeData };
  };

  const { labels, data: chartData } = processData(data, timeFrame);

  const dataConfig = {
    labels,
    datasets: [
      {
        label: "Total Reach",
        data: chartData,
        fill: true,
        backgroundColor: colors.blueLink + "80",
        borderColor: colors.blueLink,
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <StyledChartContainer>
      <Line data={dataConfig} options={options} />
      <ChartFilterSelector $color={colors.blueLink}>
        <select
          id="timeFrameSelector"
          value={timeFrame}
          onChange={(e) => setTimeFrame(e.target.value)}
        >
          {["day", "month", "year"].map((time) => (
            <option key={time} value={time}>
              {time.charAt(0).toUpperCase() + time.slice(1)}
            </option>
          ))}
        </select>
      </ChartFilterSelector>
    </StyledChartContainer>
  );
};
