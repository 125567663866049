import { useEffect, useState } from "react";

export const useViewport = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowSize <= 780;
  const isTablet = windowSize > 780 && windowSize <= 1024;
  const isDesktop = windowSize > 1024;

  return { isMobile, isTablet, isDesktop };
};
