import { UseToastOptions, useToast } from "@chakra-ui/react";

export const useShowToast = () => {
  const toast = useToast();

  const showToast = (message: string, status: UseToastOptions["status"]) => {
    const title =
      status === "error"
        ? "Error"
        : status === "info"
        ? "Notice"
        : status === "success"
        ? "Success"
        : status === "warning"
        ? "Heads up"
        : "Loading";

    toast({
      title,
      description: message,
      status,
      duration: 9000,
      isClosable: true,
    });
  };

  return { showToast };
};
