import { useLocation, useParams } from "react-router-dom";

interface ParamsObject {
  [key: string]: string | undefined;
}

export const useUrlParams = () => {
  const routeParams = useParams<ParamsObject>();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject: ParamsObject = {};

  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });

  return {
    routeParams,
    queryParams: queryParamsObject,
  };
};
