import { ButtonHTMLAttributes } from "react";
import { colors } from "../colors";
import { Show } from "./Show";
import { Button as ChakraButton } from "@chakra-ui/react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: "primary" | "secondary" | "menu";
  label: string;
  isLoading?: boolean;
  themeColor?: string;
  disabled?: boolean;
}

export const Button = (props: ButtonProps) => {
  const {
    buttonType,
    label,
    onClick,
    isLoading,
    themeColor,
    disabled,
    ...rest
  } = props;
  return (
    <>
      <Show when={buttonType === "primary"}>
        <ChakraButton
          bgColor={themeColor ?? colors.black}
          color={themeColor ? colors.white : colors.gold}
          _hover={{ opacity: 0.85 }}
          cursor={"pointer"}
          margin={"1"}
          _active={{ transform: "none" }}
          onClick={onClick}
          isDisabled={disabled}
          isLoading={isLoading}
          {...rest}
        >
          {label}
        </ChakraButton>
      </Show>
      <Show when={buttonType === "secondary"}>
        <ChakraButton
          bgColor={"transparent"}
          color={colors.black}
          border={"1px"}
          _hover={{ bgColor: colors.white }}
          cursor={"pointer"}
          margin={"1"}
          onClick={onClick}
          isDisabled={disabled}
          isLoading={isLoading}
          {...rest}
        >
          {label}
        </ChakraButton>
      </Show>
      <Show when={buttonType === "menu"}>
        <ChakraButton
          bgColor={"transparent"}
          size={"lg"}
          color={colors.black}
          border={"0px"}
          _hover={{ bgColor: colors.white }}
          cursor={"pointer"}
          margin={"1"}
          onClick={onClick}
          isDisabled={disabled}
          isLoading={isLoading}
          {...rest}
        >
          {label}
        </ChakraButton>
      </Show>
    </>
  );
};
