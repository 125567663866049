import styled from "styled-components";
import { colors } from "../colors";

const ProgressBarWrapper = styled.div`
  width: 100%;
  background-color: #e0e0df;
  border-radius: 0.5rem;
  position: relative;
  height: 1rem;
`;

const FilledBar = styled.div<{ $percentage: number }>`
  height: 100%;
  background-color: ${(props) => props.color || colors.gold};
  width: ${(props) => props.$percentage}%;
  transition: width 0.3s ease-in-out;
  border-radius: 0.5rem;
`;

export type ProgressBarTypes = {
  value: number;
  max: number;
  color: string;
};

export const ProgressBar = (props: ProgressBarTypes) => {
  const { value, max, color } = props;
  const percentage = ((value < max ? value : max) / max) * 100;

  return (
    <ProgressBarWrapper>
      <FilledBar $percentage={percentage} color={color} />
    </ProgressBarWrapper>
  );
};
