import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }

  useEffect(() => {
    if (
      context.isFinished &&
      !context.accessToken &&
      !context.stripeConnected
    ) {
      navigate("/");
    }
  }, [
    context.accessToken,
    context.isFinished,
    context.stripeConnected,
    navigate,
  ]);

  return context;
};
