import axios from "axios";
import { buildAuthHeaders } from "../buildAuthHeaders";
import { rootUrl } from "../rootUrl";

type UpdateUserResponse = {
  id: string;
};

export type UpdateUserRequest = {
  website?: string;
  logo?: string;
};

const endpoint = `/user/update`;

export const patchUpdateUser = async (
  body: UpdateUserRequest,
  accessToken: string
): Promise<UpdateUserResponse> => {
  const { data } = await axios.patch<UpdateUserResponse>(
    `${rootUrl}${endpoint}`,
    body,
    buildAuthHeaders(accessToken)
  );
  return data;
};
