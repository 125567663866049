import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormEvent, useRef, useState } from "react";
import { Button } from "../Button";
import { useModal } from "../../hooks/useModal";
import { colors } from "../../colors";
import { useShowToast } from "../../hooks/useShowToast";
import { useAuth } from "../../hooks/useAuth";

export const SignInModal = () => {
  const { signIn } = useAuth();
  const { closeModal, activeModal } = useModal();
  const { showToast } = useShowToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  if (activeModal !== "sign-in") return null;

  const handleSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);

    if (!email || !password) {
      showToast("You must enter an email and password!", "warning");
      setIsProcessing(false);
      return;
    }

    const { data, error } = await signIn(email, password);

    if (error) {
      if (error.status === 400 || error.message === "Invalid login") {
        showToast("Invalid credentials!", "error");
      } else {
        showToast("An unknown error as occurred!", "error");
      }
      setIsProcessing(false);
      return;
    }

    if (data.session?.access_token) {
      showToast("You've successfully signed in 😎", "success");
    } else {
      showToast("An unknown error as occurred!", "error");
    }
    setIsProcessing(false);
    closeModal();
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={true}
      onClose={closeModal}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent margin="auto">
        <form onSubmit={handleSignIn}>
          <ModalHeader fontSize={"2xl"}>Sign In</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                ref={initialRef}
                placeholder="mary@example.com"
                focusBorderColor={colors.gold + "60"}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Password</FormLabel>
              <Input
                placeholder="Password"
                focusBorderColor={colors.gold + "60"}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={closeModal}
              buttonType="secondary"
              label="Cancel"
              type="button"
            />
            <Button
              buttonType="primary"
              label="Sign In"
              isLoading={isProcessing}
              type="submit"
            />
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
