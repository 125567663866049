import styled from "styled-components";
import { Gold, Image } from "../Reusable";
import { Heading, Text } from "@chakra-ui/react";
import icon from "../../assets/icon.svg";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";
import { Show } from "../Show";
import { useAuth } from "../../hooks/useAuth";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -4rem;
`;

export const Welcome = () => {
  const navigate = useNavigate();
  const { onboardingResults } = useAuth();

  const welcome = (
    <>
      <Heading>The Ultimate Fundraising Tool</Heading>
      <Text w={"50%"} textAlign={"center"} mb={"1rem"}>
        Through the power of <Gold>trust</Gold> and <Gold>mobile giving</Gold>,
        KISS Gives enables you to effortlessly increase donations from your
        existing network. Let's level up your next raise!
      </Text>
      <Button
        buttonType="primary"
        label="Start Fundraising"
        onClick={() => navigate("/dashboard/start")}
      />
    </>
  );

  const welcomeBack = (
    <>
      <Heading>Welcome Back!</Heading>
      <Text w={"60%"} textAlign={"center"} mb={"1rem"}>
        Let's do something good today.
      </Text>
    </>
  );

  return (
    <Container>
      <Image $fadeIn src={icon} />
      <Show
        when={onboardingResults.charges && onboardingResults.details}
        whenFalseContent={welcome}
      >
        {welcomeBack}
      </Show>
    </Container>
  );
};
