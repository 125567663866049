import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Button } from "../Button";
import { useModal } from "../../hooks/useModal";
import { colors } from "../../colors";
import { Show } from "../Show";
import { styled } from "styled-components";
import icon from "../../assets/icon.svg";
import { Image } from "../Reusable";
import { useShowToast } from "../../hooks/useShowToast";
import { useAuth } from "../../hooks/useAuth";
import { postCreateUser } from "../../api/user/create";
import { Session } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";
import { EinSearchBar } from "../EINSearchBar";
import { EINData } from "../../api/user/einLookup";

const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30rem;
`;

const TermsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Link = styled.a`
  color: ${colors.gold};
`;

export const CreateAccountModal = () => {
  const { signUp, setStripeConnected } = useAuth();
  const { closeModal, activeModal } = useModal();
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [password, setPassword] = useState("");
  const [ein, setEin] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);
  const { showToast } = useShowToast();
  const navigate = useNavigate();
  const [termsSelected, setTermsSelected] = useState(false);
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  useEffect(() => {
    return () => setEin("");
  }, []);

  if (activeModal !== "create-account") return null;

  localStorage.setItem("onboarding", "true");

  const handleCreateAccount = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsProcessing(true);

      if (!organization || !email || !password) {
        showToast("You must enter all fields!", "warning");
        return;
      }

      if (confirmPassword !== password) {
        showToast("The passwords you entered do not match!", "warning");
        return;
      }

      const { data, error } = await signUp(email, password);

      if (error) {
        if (error.message === "User already registered") {
          showToast("This user already exists! Sign in instead.", "error");
        } else {
          showToast("There was an error completing the request!", "error");
        }
        return;
      }

      const sessionData: Session = data.session;

      if (sessionData?.access_token) {
        const res = await postCreateUser(
          sessionData.user.id,
          email,
          organization,
          ein,
          sessionData.access_token
        );

        if (!res.id) {
          showToast("Failed to create user!", "error");
        } else {
          showToast("Your account has been created 💪", "success");
          setShowWelcomeDialog(true);
          setStripeConnected(!!res.id);
        }
      } else {
        showToast("An unknown error has occurred!", "error");
      }
    } catch (error) {
      showToast("There was an error completing the request!", "error");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleViewDashboard = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    localStorage.setItem("onboarding", "false");
    navigate("/dashboard");
  };

  const handleSearchSelectResult = (result: EINData) => {
    setOrganization(result.name);
    setEin(result.ein);
  };

  const einSelect = (
    <FormControl>
      <FormLabel>First, let's find your non-profit.</FormLabel>
      <EinSearchBar onSelectResult={handleSearchSelectResult} />
    </FormControl>
  );

  const form = (
    <form onSubmit={handleCreateAccount} style={{ padding: "1rem" }}>
      <ModalHeader fontSize={"2xl"}>📝 Register Organization</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6} minHeight={"6rem"}>
        <Show when={!!ein} whenFalseContent={einSelect}>
          <FormControl>
            <FormLabel>Organization Name</FormLabel>
            <Input
              placeholder={organization}
              focusBorderColor={colors.gold + "60"}
              onChange={(e) => setOrganization(e.target.value)}
              type="text"
              disabled
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="mary@example.com"
              focusBorderColor={colors.gold + "60"}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Password</FormLabel>
            <Input
              placeholder="Password"
              focusBorderColor={colors.gold + "60"}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Re-Enter Password</FormLabel>
            <Input
              placeholder="Password"
              focusBorderColor={colors.gold + "60"}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
            />
          </FormControl>
          <FormControl mt={4}>
            <TermsContainer>
              <Checkbox
                marginRight={"1rem"}
                isChecked={termsSelected}
                onChange={() => setTermsSelected(!termsSelected)}
              />
              <Text>
                By creating an account you're agreeing to KISS Give's{" "}
                <Link href={"/tos"} target="_blank">
                  terms of service
                </Link>{" "}
                and our{" "}
                <Link href={"/sms-policy"} target="_blank">
                  SMS text policy
                </Link>
                .
              </Text>
            </TermsContainer>
          </FormControl>
        </Show>
      </ModalBody>
      <Show when={!!ein}>
        <ModalFooter>
          <Button
            onClick={() => {
              setEin("");
              closeModal();
            }}
            buttonType="secondary"
            label="Cancel"
            type="button"
          />
          <Button
            buttonType="primary"
            label="Create"
            isLoading={isProcessing}
            type="submit"
            disabled={!termsSelected}
          />
        </ModalFooter>
      </Show>
    </form>
  );

  const welcomeDialog = (
    <>
      <WelcomeWrapper>
        <Image src={icon} />
        <ModalHeader margin={"-3"} fontSize={"3xl"}>
          Welcome To KISS Gives
        </ModalHeader>
        <Text>We're happy you made it!</Text>
        <div style={{ marginTop: "2rem" }}>
          <Button
            onClick={handleViewDashboard}
            buttonType="primary"
            label="View Dashboard"
            type="button"
          />
        </div>
      </WelcomeWrapper>
    </>
  );

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={true}
      onClose={() => {
        setEin("");
        closeModal();
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent margin="auto">
        <Show when={showWelcomeDialog} whenFalseContent={form}>
          {welcomeDialog}
        </Show>
      </ModalContent>
    </Modal>
  );
};
