import { useState, useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { colors } from "../../../colors";
import { ChartFilterSelector, StyledChartContainer } from "../../Reusable";
import { AnalyticsAmount } from "../../../api/user/getAnalytics";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface AmountChartProps {
  data: AnalyticsAmount[];
}

export const AmountChart = ({ data }: AmountChartProps) => {
  const [timeFrame, setTimeFrame] = useState("day");

  const aggregateData = (data: AnalyticsAmount[], timeFrame: string) => {
    // Explicitly type groupedData
    const groupedData: Record<string, number> = {};
    data.forEach((item) => {
      const date = new Date(item.createdAt);
      let label: string;
      switch (timeFrame) {
        case "day":
          label = date.toLocaleDateString();
          break;
        case "month":
          label = `${date.getMonth() + 1}-${date.getFullYear()}`;
          break;
        case "year":
          label = date.getFullYear().toString();
          break;
        default:
          label = date.toLocaleDateString();
      }
      if (!groupedData[label]) {
        groupedData[label] = 0;
      }
      groupedData[label] += item.amount / 100;
    });
    return Object.keys(groupedData).map((key) => ({
      label: key,
      amount: groupedData[key],
    }));
  };

  const { labels, chartData } = useMemo(() => {
    const aggregatedData = aggregateData(data, timeFrame);
    return {
      labels: aggregatedData.map((d) => d.label),
      chartData: aggregatedData.map((d) => d.amount),
    };
  }, [data, timeFrame]);

  const dataConfig = {
    labels,
    datasets: [
      {
        label: "Amount Raised",
        data: chartData,
        fill: true,
        backgroundColor: `${colors.gold}80`,
        borderColor: colors.gold,
        tension: 0.4,
      },
    ],
  };

  return (
    <StyledChartContainer style={{ marginRight: "0.5rem" }}>
      <Line data={dataConfig} />
      <ChartFilterSelector $color={colors.gold}>
        <select
          value={timeFrame}
          onChange={(e) => setTimeFrame(e.target.value)}
        >
          {["day", "month", "year"].map((frame) => (
            <option key={frame} value={frame}>
              {frame.charAt(0).toUpperCase() + frame.slice(1)}
            </option>
          ))}
        </select>
      </ChartFilterSelector>
    </StyledChartContainer>
  );
};
