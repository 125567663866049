import styled from "styled-components";
import applePay from "../assets/apple-pay.png";
import googlePay from "../assets/google-pay.png";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3rem;
  margin: 1rem;
`;

const StyledImage = styled.img`
  width: 100px;
  height: auto;
  margin: 1rem;
`;

export const PaymentOptions = () => {
  return (
    <Container>
      <StyledImage alt="Apple Pay Logo" src={applePay} />
      <StyledImage alt="Google Pay Logo" src={googlePay} />
    </Container>
  );
};
