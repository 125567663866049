import styled from "styled-components";
import { colors } from "../colors";
import icon from "../assets/icon.svg";
import { Image } from "../components/Reusable";
import { useViewport } from "../hooks/useViewport";

const Container = styled.div<{ $isDesktop: boolean }>`
  font-family: "Helvetica Neue", sans-serif;
  margin: 1.25rem;
  margin: 0 auto;
  padding: ${(props) => (props.$isDesktop ? "3rem 12rem" : "3rem 2rem")};
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${colors.black};
`;

const SubTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 500;
  color: ${colors.black};
  margin-top: 1.25rem;
  text-transform: uppercase;
`;

const Paragraph = styled.p`
  font-size: 1.25rem;
  color: ${colors.black};
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const List = styled.ul`
  list-style-type: disc;
  margin-left: 1.25rem;
`;

const ListItem = styled.li`
  color: ${colors.black};
  font-size: 1.15rem;
`;

export const TermsOfService = () => {
  const { isDesktop } = useViewport();
  return (
    <Container $isDesktop={isDesktop}>
      <Image src={icon} />
      <Title>TERMS OF SERVICE</Title>
      <Paragraph>Effective Date: November 7, 2023</Paragraph>

      <SubTitle>1. ACCEPTANCE OF TERMS</SubTitle>
      <Paragraph>
        Welcome to KissGives, LLC ("KissGives"). By accessing or using
        KissGives' tech platform (the "Platform"), you agree to comply with and
        be bound by these Terms of Use. If you do not agree to these terms,
        please do not use the Platform.
      </Paragraph>

      <SubTitle>2. USE OF THE PLATFORM</SubTitle>
      <Paragraph>
        <Strong>2.1 License:</Strong> Subject to these Terms, KissGives grants
        you a non-exclusive, revocable, non-transferable, limited license to use
        the Platform for your personal, non-commercial purposes.
      </Paragraph>
      <Paragraph>
        <Strong>2.2 Restrictions:</Strong> You may not:
      </Paragraph>
      <List>
        <ListItem>
          Use the Platform for any illegal, unauthorized, or unethical purpose.
        </ListItem>
        <ListItem>
          Interfere with or disrupt the operation of the Platform.
        </ListItem>
        <ListItem>Modify, adapt, or hack the Platform.</ListItem>
        <ListItem>Transmit any viruses or malicious code.</ListItem>
        <ListItem>Violate any applicable laws or regulations.</ListItem>
      </List>

      <SubTitle>3. DISCLAIMER OF WARRANTIES</SubTitle>
      <Paragraph>
        You expressly understand and agree that your use of the Platform is at
        your sole risk. The Platform is provided "as is" and "as available"
        without warranties of any kind, either express or implied.
      </Paragraph>

      <SubTitle>4. LIMITATION OF LIABILITY</SubTitle>
      <Paragraph>
        To the fullest extent permitted by applicable law, KissGives shall not
        be liable for any indirect, incidental, special, consequential, or
        punitive damages, or any loss of profits or revenues, whether incurred
        directly or indirectly.
      </Paragraph>

      <SubTitle>5. INDEMNIFICATION</SubTitle>
      <Paragraph>
        You agree to indemnify and hold harmless KissGives and its affiliates,
        directors, officers, employees, and agents from any and all claims,
        damages, liabilities, and expenses arising out of your use of the
        Platform or any violation of these Terms.
      </Paragraph>

      <SubTitle>6. COMMUNITY GUIDELINES AND PROHIBITED USE</SubTitle>
      <Paragraph>
        You are solely responsible for compliance with all applicable laws in
        relation to your use of the Services. You are further solely responsible
        for all User-Generated Content that you upload, post, publish, display,
        transmit, or otherwise use (hereinafter, “Upload”).
      </Paragraph>
      <Paragraph>
        KissGives does not permit the use of its Platform for any purpose or
        campaign that involves:
      </Paragraph>
      <List>
        <ListItem>
          <Strong>Breaking the law:</Strong> the violation of any law or
          regulation.
        </ListItem>
        <ListItem>
          <Strong>Fraud:</Strong> information that is fraudulent, misleading,
          inaccurate, dishonest, or impossible, particularly if done knowingly
          with the intent to deceive.
        </ListItem>
        <ListItem>
          <Strong>Illegal drugs:</Strong> narcotics, steroids, controlled
          substances, abuse of pharmaceuticals or similar products or therapies
          that are either illegal or prohibited.
        </ListItem>
        <ListItem>
          <Strong>Violence:</Strong> knives, explosives, ammunition, firearms,
          or other weaponry or accessories, as well as the promotion of violent
          ideas such as genocide, execution, and other violent acts.
        </ListItem>
        <ListItem>
          <Strong>Financial schemes:</Strong> annuities, investments, loans,
          equity or lottery contracts, lay-away systems, off-shore banking or
          similar transactions, money service businesses (including currency
          exchanges, check cashing or the like), pyramid schemes, “get rich
          quick schemes” (i.e., investment opportunities or other services that
          promise high rewards), network marketing and referral marketing
          programs, debt collection, or cryptocurrencies.
        </ListItem>
        <ListItem>
          <Strong>Gambling:</Strong> casino games, sports betting, fantasy
          sports, horse or greyhound racing, lottery tickets, and other ventures
          that facilitate gambling, games of skill or chance (whether or not, it
          is legally defined as a lottery), promotions involving monetary
          rewards, including gift cards, or sweepstakes.
        </ListItem>
        <ListItem>
          <Strong>Hate, discrimination, and harassment:</Strong> content that we
          deem, in our sole discretion, to be in support of hate, violence,
          harassment, bullying, discrimination, terrorism, or intolerance of any
          kind relating to race, ethnicity, national origin, religious
          affiliation, sexual orientation, sex, gender or gender identity, or
          mental, physical, or other medical conditions.
        </ListItem>
        <ListItem>
          <Strong>Legal defense for serious crimes:</Strong> campaigns that we
          deem, in our sole discretion, to be for the legal defense of alleged
          crimes associated with hate, violence, harassment, bullying,
          discrimination, terrorism, or intolerance of any kind relating to
          race, ethnicity, national origin, religious affiliation, sexual
          orientation, sex, gender or gender identity, mental, physical, or
          other medical conditions, or financial crimes or crimes of deception.
        </ListItem>
        <ListItem>
          <Strong>International sanctions:</Strong> activities with, in, or
          involving countries, regions, governments, persons, or entities that
          are subject to U.S. and other economic sanctions under applicable law
          unless such activities are expressly authorized by the appropriate
          governmental authority.
        </ListItem>
        <ListItem>
          <Strong>Payment manipulation:</Strong> collecting payments on behalf
          of merchants by payment processors or otherwise; including, but not
          limited to, self-payments on campaigns or an attempt to bypass or
          otherwise circumvent the designated method of payment as provided by
          Company.
        </ListItem>
        <ListItem>
          <Strong>Misuse of funds:</Strong> the receipt or grant of cash
          advances or lines of credit to yourself or to another person for
          purposes other than those purposes clearly stated in the campaign or
          collecting or providing funds for any purpose other than as described
          in a campaign description.
        </ListItem>
        <ListItem>
          <Strong>Exploitation:</Strong> funding a ransom, human trafficking or
          exploitation, vigilantism, bribes or bounty.
        </ListItem>
        <ListItem>
          <Strong>Pornography:</Strong> pornographic content or other sexual
          content relating to minors or persons appearing to possibly be minors.
        </ListItem>
        <ListItem>
          <Strong>Graphic:</Strong> offensive, graphic, perverse or sensitive
          content.
        </ListItem>
        <ListItem>
          <Strong>Pre-sales:</Strong> the sale of items before the seller has
          control or possession of the item.
        </ListItem>
        <ListItem>
          <Strong>Credit:</Strong> credit repair or debt settlement services.
        </ListItem>
        <ListItem>
          <Strong>Slander and personal attacks:</Strong> publication or removal
          of User-Generated Content (such as mug shots), where the primary
          purpose of posting such User-Generated Content is to cause or raise
          concerns of reputational harm, as well as personal attacks, slurs,
          caustic or combative content submitted with the aim of harming other
          people in some way.
        </ListItem>
        <ListItem>
          <Strong>No-value-added services:</Strong> sale or resale of a service
          without added benefit to the buyer or resale of government offerings
          without authorization or added value.
        </ListItem>
        <ListItem>
          <Strong>Money laundering:</Strong> aggregation of funds owed to third
          parties, factoring, or other activities intended to obfuscate the
          origin of funds.
        </ListItem>
        <ListItem>
          <Strong>Piracy:</Strong> counterfeit music, movies, software, or other
          licensed materials without the appropriate authorization from the
          rights holder.
        </ListItem>
        <ListItem>
          <Strong>Intellectual property violations:</Strong> products or
          services that directly infringe or facilitate infringement upon the
          trademark, patent, copyright, trade secrets, or proprietary or privacy
          rights of any third party.
        </ListItem>
        <ListItem>
          <Strong>Unauthorized sale or resale:</Strong> of brand name or
          designer products or services.
        </ListItem>
        <ListItem>
          <Strong>Trade violations:</Strong> sale of goods or services that are
          illegally imported or exported.
        </ListItem>
        <ListItem>
          <Strong>Credit card fraud:</Strong> processing where there is no bona
          fide donation accepted; cash advance; card testing; or evasion of card
          network chargeback monitoring programs.
        </ListItem>
        <ListItem>
          <Strong>Financial crimes:</Strong> any other activity that Company may
          deem in its sole discretion to be in support of individuals and/or
          entities associated with alleged financial crimes, including, but not
          limited to, corruption, bribery, tax evasion, fraud, and activities of
          a similar nature; or
        </ListItem>
        <ListItem>
          <Strong>Generally unacceptable activity:</Strong> any other activity
          that Company may deem in its sole discretion to be unacceptable.
        </ListItem>
      </List>
      <SubTitle>7. TERMINATION</SubTitle>
      <Paragraph>
        KissGives reserves the right to terminate your access to the Platform,
        without cause or notice.
      </Paragraph>
      <SubTitle>8. GOVERNING LAW</SubTitle>
      <Paragraph>
        These Terms shall be governed by and construed in accordance with the
        laws of Delaware.
      </Paragraph>
      <SubTitle>9. CONTACT INFORMATION</SubTitle>
      <Paragraph>
        If you have any questions about these Terms, please contact KissGives at
        hello@kissgives.com By using the Platform, you agree to these Terms of
        Use.
      </Paragraph>
    </Container>
  );
};
