import { FileUploader } from "react-drag-drop-files";
import { colors } from "../colors";

const fileTypes = ["JPG", "PNG", "JPEG"];

export type LogoFile = {
  name: string;
  type: MimeTypes;
  base64: string;
};

export type MimeTypes = "image/jpeg" | "image/png";

export type DragDropProps = {
  onLogoChange: (logo: LogoFile) => void;
};

export const DragDrop = (props: DragDropProps) => {
  const { onLogoChange } = props;
  const handleChange = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        onLogoChange({
          name: file.name,
          type: file.type,
          base64: reader.result.toString(),
        });
      }
    };
    reader.onerror = (error) => {
      console.error("Error: ", error);
    };
  };

  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      types={fileTypes}
      multiple={false}
      label="Upload or drop your logo here"
      hoverTitle=""
      maxSize={1}
      dropMessageStyle={{ backgroundColor: colors.gold + 10 }}
    />
  );
};
