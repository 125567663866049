// AuthContext.tsx
import React, { createContext, useEffect, useState, ReactNode } from "react";
import supabase from "../supabase";
import { AuthResponse, AuthTokenResponse } from "@supabase/supabase-js";
import { sleep } from "../utils/sleep";
import {
  OnboardingStatusResponse,
  getOnboardingStatus,
} from "../api/user/onboardingStatus";
import { rootUrl } from "../api/rootUrl";

export interface KissAuth {
  accessToken: string | null;
  setStripeConnected: React.Dispatch<React.SetStateAction<boolean>>;
  onboardingResults: OnboardingStatusResponse;
  stripeConnected: boolean;
  isFinished: boolean;
  getOnboardingResults: (token: string) => Promise<OnboardingStatusResponse>;
  signUp: (email: string, password: string) => Promise<any>; // Update the return type appropriately
  signIn: (email: string, password: string) => Promise<any>; // Update the return type appropriately
  signOut: () => void;
}

export const AuthContext = createContext<KissAuth | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [stripeConnected, setStripeConnected] = useState<boolean>(false);
  const [onboardingResults, setOnboardingResults] =
    useState<OnboardingStatusResponse>({
      organization: "",
      website: "",
      logo: "",
      stripeId: "",
      charges: false,
      details: false,
      link: "",
    });
  const [isFinished, setIsFinished] = useState(false);

  const getOnboardingResults = async (token: string) => {
    const onboardingRes = await getOnboardingStatus(token, rootUrl);
    setOnboardingResults(onboardingRes);
    setStripeConnected(!!onboardingRes.stripeId);
    return onboardingRes;
  };

  useEffect(() => {
    const getSession = async () => {
      try {
        const { data, error } = await supabase.auth.getSession();

        if (error) {
          setAccessToken(null);
          console.log(error);
          return;
        }

        if (!data.session?.user) {
          setAccessToken(null);
          if (window.location.pathname.includes("dashboard")) {
            window.location.href = "/";
          }
          return;
        }

        const token = data.session.access_token;
        await getOnboardingResults(token);
        setAccessToken(token);
        sleep(100).then(() => setIsFinished(true));
      } catch (error) {
        setAccessToken(null);
        console.log(error);
      }
    };

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setAccessToken(session?.access_token ?? null);
      }
    );

    getSession();

    return () => {
      if (authListener) authListener.subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUp = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    return await supabase.auth.signUp({
      email,
      password,
    });
  };

  const signIn = async (
    email: string,
    password: string
  ): Promise<AuthTokenResponse> => {
    return await supabase.auth.signInWithPassword({
      email,
      password,
    });
  };

  const signOut = async () => {
    await supabase.auth.signOut();
    window.location.href = "/?signOut=true";
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        signUp,
        signIn,
        signOut,
        getOnboardingResults,
        onboardingResults,
        stripeConnected,
        setStripeConnected,
        isFinished,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
