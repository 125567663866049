import styled from "styled-components";
import { colors } from "../colors";

const StyledBadge = styled.span<{ $themeColor?: string }>`
  display: inline-block;
  padding: 0.375rem 0.75rem;
  margin: 1rem;
  background-color: ${(props) => (props.$themeColor ?? colors.gold) + "40"};
  color: ${(props) => props.$themeColor ?? colors.gold};
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
`;

export type StyledBadgeProps = {
  text: string;
  themeColor?: string;
};

export const Badge = (props: StyledBadgeProps) => {
  const { text, themeColor } = props;
  return (
    <StyledBadge $themeColor={themeColor}>{text.toUpperCase()}</StyledBadge>
  );
};
