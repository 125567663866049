import { styled } from "styled-components";
import { colors } from "../colors";
import { useEffect, useState } from "react";
import icon from "../assets/icon.svg";
import { Show } from "../components/Show";
import { TypeEffect } from "../components/TypeEffect";
import { MenuItem, TopNav } from "../components/TopNav";
import { useViewport } from "../hooks/useViewport";
import { HeaderText, Image, fadeIn } from "../components/Reusable";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useUrlParams } from "../hooks/useUrlParams";
import { useShowToast } from "../hooks/useShowToast";
import { clearParams } from "../utils/clearParams";
import { CreateAccountModal } from "../components/modals/CreateAccountModal";
import { SignInModal } from "../components/modals/SignInModal";
import mobileDonateUi from "../assets/mobile-donate-ui.png";
import { HowItWorksInfoText } from "../components/HowItWorksInfoText";
import { PaymentOptions } from "../components/PaymentOptions";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const HeaderSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
`;

const Content = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => (props.$isMobile ? "90%" : "80%")};
`;

const EmailContact = styled.p<{ $isMobile?: boolean }>`
  font-size: ${(props) => (props.$isMobile ? "1.25rem" : "1.75rem")};
  font-weight: 400;
  color: ${colors.gold};
  animation: ${fadeIn} 20s ease-in-out;
  width: ${(props) => (props.$isMobile ? "90%" : "80%")};
  margin-bottom: 3rem;
`;

const SubTitle = styled.p`
  font-size: 1.75rem;
  font-weight: 500;
  color: ${colors.black + "50"};
  animation: ${fadeIn} 3s ease-in-out;
  width: 100%;
  margin: 0.5rem 0;
`;

const TypeWrapper = styled.div`
  width: 100%;
  margin: 0;
  line-height: 3.25rem;
`;

const EmptyImageSpace = styled.div`
  width: 4.75rem;
  height: 4.75rem;
`;

const EmptyTextSpace = styled.div`
  width: 3.5rem;
  height: 3.5rem;
`;

const HowItWorksSection = styled.section<{ $isMobile: boolean }>`
  background: ${colors.lightGray};
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.$isMobile ? undefined : "100vh")};
  padding: ${(props) => (props.$isMobile ? "2rem 0.25rem" : "4rem")};
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const LeftSectionContent = styled.div<{ $isDesktop?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => (props.$isDesktop ? "50%" : "100%")};
`;

const SectionHeaderText = styled(HeaderText)`
  font-size: 3rem;
  text-align: left;
  padding-left: 1rem;
`;

const SectionInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
`;

const SectionInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

const InnerSectionImage = styled.img<{ $isMobile: boolean }>`
  width: 90%;
  height: auto;
  max-width: ${(props) => (props.$isMobile ? "20rem" : "35rem")};
`;

const RightSectionContent = styled(LeftSectionContent)`
  width: 50%;
  align-items: center;
`;

const PricingSection = styled.section<{ $isMobile: boolean }>`
  background: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: ${(props) => (props.$isMobile ? "0.5rem" : "4rem")};
`;

export const Landing = () => {
  const { accessToken, stripeConnected } = useAuth();
  const [typeEffectFinished, setTypeEffectFinished] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(false);
  const { isMobile, isDesktop } = useViewport();
  const navigate = useNavigate();
  const { queryParams } = useUrlParams();
  const { showToast } = useShowToast();
  const onboarding = localStorage.getItem("onboarding") === "true";

  useEffect(() => {
    if (queryParams.signOut === "true") {
      showToast("Signed out. Until next time 👋", "success");
    }

    clearParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((accessToken || stripeConnected) && !onboarding) {
      navigate("/dashboard");
    }
  }, [accessToken, navigate, onboarding, stripeConnected]);

  const handleMenuClick = (item: MenuItem) => {
    // Scroll to the target section with smooth behavior
    const targetSection = document.getElementById(item);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <MainContainer>
      <HeaderSection>
        <CreateAccountModal />
        <SignInModal />
        <TopNav onMenuClick={handleMenuClick} />
        <Content $isMobile={isMobile}>
          <Show
            when={typeEffectFinished}
            whenFalseContent={<EmptyImageSpace />}
          >
            <Image $fadeIn src={icon} />
          </Show>
          <TypeWrapper>
            <TypeEffect
              onFinish={() => {
                setTypeEffectFinished(true);
                setTimeout(() => setShowSubtitle(true), 3000);
              }}
            />
          </TypeWrapper>
          <Show when={showSubtitle} whenFalseContent={<EmptyTextSpace />}>
            <SubTitle>Moving purpose forward.</SubTitle>
          </Show>
        </Content>
        <EmailContact $isMobile={isMobile}>hello@kissgives.com</EmailContact>
      </HeaderSection>
      <HowItWorksSection id="how-it-works" $isMobile={isMobile}>
        <Content $isMobile={isMobile}>
          <SectionContainer>
            <LeftSectionContent
              style={{ width: isDesktop ? "60%" : undefined }}
              $isDesktop={isDesktop}
            >
              <SectionHeaderText>How It Works</SectionHeaderText>
              <SectionInfoContainer>
                <SectionInfo>
                  <HowItWorksInfoText
                    title="Create Account"
                    description="We collect enough information to legally process payments and fund your bank account."
                    icon={"create-account"}
                  />
                  <HowItWorksInfoText
                    title="Secure Payments"
                    description="Funds are sent directly from the donor to your bank account via Stripe. KISS never touches the money."
                    icon={"secure"}
                  />
                  <HowItWorksInfoText
                    title="Messaging"
                    description="Send SMS messages or custom emails the moment you are inspired."
                    icon={"message"}
                  />
                  <HowItWorksInfoText
                    title="Own Your Data"
                    description="Unlike all other platforms, we do not use or sell your donors data."
                    icon={"data"}
                  />
                </SectionInfo>
              </SectionInfoContainer>
            </LeftSectionContent>
            <Show when={isDesktop}>
              <RightSectionContent>
                <InnerSectionImage $isMobile={isMobile} src={mobileDonateUi} />
                <PaymentOptions />
              </RightSectionContent>
            </Show>
          </SectionContainer>
        </Content>
      </HowItWorksSection>
      <PricingSection id="pricing" $isMobile={isMobile}>
        <HeaderText
          style={{
            color: colors.white,
            fontSize: isMobile ? "2.5rem" : "4rem",
          }}
        >
          Simple Pricing
        </HeaderText>
        <SubTitle
          style={{
            color: colors.white + "85",
            width: isMobile ? "90%" : "60%",
            fontSize: isMobile ? "1rem" : "1.5rem",
          }}
        >
          Donors have the option to tip KISS during checkout. If they do, your
          fees are zero, if they don’t you pay 1% plus $0.30 per transaction.
        </SubTitle>
        <SubTitle
          style={{
            color: colors.white,
            marginTop: "3rem",
            fontSize: isMobile ? "1rem" : "2rem",
            fontWeight: 700,
          }}
        >
          No other fees.
        </SubTitle>
      </PricingSection>
    </MainContainer>
  );
};
