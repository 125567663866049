import {
  Avatar,
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  Heading,
  IconButton,
  Select,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Button } from "../components/Button";
import { colors } from "../colors";
import kissIcon from "../assets/icon.svg";
import { Image } from "../components/Reusable";
import { useViewport } from "../hooks/useViewport";
import { Show } from "../components/Show";
import { useUrlParams } from "../hooks/useUrlParams";
import { GetCampaignResponse, getCampaign } from "../api/campaign/getCampaign";
import { formatNumber } from "../utils/format";
import {
  PostCheckoutSessionRequest,
  getCheckoutSession,
} from "../api/pay/checkoutSession";
import { useShowToast } from "../hooks/useShowToast";
import { OtherAmount } from "../components/OtherAmount";
import { SocialCard } from "../components/SocialCard";
import { RecentDonationsTicker } from "../components/RecentDonations";
import { AlertMessage } from "../components/AlertMessage";
import { CountdownTimer } from "../components/CountdownTimer";
import { Badge } from "../components/Badge";
import { ProgressBar } from "../components/ProgressBar";

const PageWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
`;

const InfoContainer = styled.div<{ $isMobile: boolean; $themeColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: ${(props) => (props.$isMobile ? "95%" : "60%")};
  height: 100vh;
  text-align: center;
  position: relative;
`;

const DonationContainer = styled(InfoContainer)`
  width: ${(props) => (props.$isMobile ? "100%" : "40%")};
  background-color: ${(props) => (props.$themeColor ?? colors.gold) + "20"};
  height: ${(props) => (props.$isMobile ? "100%" : undefined)};
  padding: 2.5rem 0;
`;

const ProgressDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.5rem;
  margin-top: 2rem;
`;

const ProgressContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 0.75rem;
  padding: 0 1.5rem;
  width: 90%;
  margin: 2rem 0 0 0;
`;

const ProgressWrapper = styled.div`
  margin: 1rem 0 0 0;
  width: 100%;
`;

const AmountsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 80%;
`;

const TotalRaisedText = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
`;

const GoalText = styled.p`
  font-size: 1rem;
`;

const ParticipantsText = styled.p`
  font-size: 0.85rem;
  color: ${colors.black + "80"};
  margin-left: 0.5rem;
`;

const LeftProgressDetails = styled.div`
  display: flex;
  align-items: center;
`;

const CampaignDescText = styled.p<{ $isMobile: boolean }>`
  font-size: 1.25rem;
  color: ${colors.black + "60"};
  text-align: center;
  width: ${(props) => (props.$isMobile ? "95%" : "75%")};
  margin-top: 0.5rem;
`;

const PoweredBy = styled.div<{ $isMobile: boolean }>`
  position: absolute;
  bottom: ${(props) => (props.$isMobile ? "1rem" : "3rem")};
`;

const Organization = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: 1rem;
  left: 1rem;
  z-index: 100;
`;

const ClientName = styled.p`
  font-size: 1rem;
  margin-left: 0.5rem;
  font-weight: 600;
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpinnerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.25rem;
`;

const BonusInfo = styled.div<{ $themeColor?: string; $isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.$themeColor + "95"};
  padding: 0.25rem 0.5rem;
  margin-top: 2rem;
  border-radius: 1rem;
  width: ${(props) => (props.$isMobile ? "60%" : "40%")};
`;

const BonusInfoText = styled.p<{ $themeColor?: string }>`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${colors.white};
`;

type GiveInterval = "one-time" | "monthly";

export const Campaign = () => {
  const [giveInterval, setGiveInterval] = useState<GiveInterval>("one-time");
  const { isMobile } = useViewport();
  const donateDiv = React.useRef<HTMLDivElement>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isCoveringFees, setIsCoveringFees] = useState(true);
  const [isTipping, setIsTipping] = useState(true);
  const [giveAnon, setGiveAnon] = useState(false);
  const [gettingLink, setGettingLink] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState<
    GetCampaignResponse | undefined
  >(undefined);
  const { showToast } = useShowToast();
  const [selectedTipPercentage, setSelectedTipPercentage] = useState(10);
  const [showOtherAmount, setShowOtherAmount] = useState(false);
  const [isTooltipOpen1, setIsTooltipOpen1] = useState(false);
  const [isTooltipOpen2, setIsTooltipOpen2] = useState(false);

  const { onCopy } = useClipboard(window.location.href.split("?")[0]);

  const { queryParams, routeParams } = useUrlParams();

  useEffect(() => {
    if (routeParams.id) {
      getCampaign(routeParams.id).then((campaign) =>
        setCampaignDetails(campaign)
      );
    }

    if (queryParams.paymentSuccess === "true") {
      setShowSuccess(true);
    }
  }, [queryParams.paymentSuccess, routeParams.id]);

  const scrollToDonateDiv = () => {
    donateDiv.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handlePayment = async (amount: number) => {
    if (!routeParams?.id) return;

    setGettingLink(true);
    const kissTipPercentage = isTipping ? selectedTipPercentage / 100 : 0;
    const kissTip = Math.round(amount * kissTipPercentage * 100);
    const data: PostCheckoutSessionRequest = {
      campaignId: routeParams.id,
      amount: amount * 100,
      isMonthly: giveInterval === "monthly",
      isCoveringFees: isCoveringFees,
      tipAmount: kissTip,
      isAnon: giveAnon,
    };

    const link = await getCheckoutSession(data);
    if (link) window.location.href = link;
  };

  const handleShareClick = () => {
    onCopy();
    showToast("Share link copied to the clipboard 💪", "success");
  };

  const generatePaymentButtons = (buttons: number[]) => {
    return buttons.map((a) => {
      return (
        <Button
          key={a}
          buttonType="primary"
          label={a === 0 ? "Other" : `$${a}`}
          type="button"
          style={{ padding: "1.5rem", fontSize: "1.5rem", minWidth: "8rem" }}
          themeColor={campaignDetails?.colorTheme}
          onClick={() =>
            a === 0 ? setShowOtherAmount(true) : handlePayment(a)
          }
        />
      );
    });
  };

  const successMessage = (
    <SuccessMessage>
      <Heading>🙏</Heading>
      <Heading>Thank You!</Heading>
      <Text>Your contribution is greatly appreciated.</Text>
      <Text
        style={{ fontSize: "1rem", fontWeight: 600, margin: "2rem 0 1rem 0" }}
      >
        Help Spread The Word
      </Text>
      <Button
        buttonType="primary"
        label="Copy Share Link 🚀"
        type="button"
        onClick={handleShareClick}
        themeColor={campaignDetails?.colorTheme}
      />
    </SuccessMessage>
  );

  const campaignInfoSection = (
    <>
      <Image
        size="6rem"
        src={campaignDetails?.logo}
        style={{ marginTop: isMobile ? "2rem" : undefined }}
      />
      <Heading fontSize={"3.5rem"}>{campaignDetails?.name}</Heading>

      <CampaignDescText $isMobile={isMobile}>
        {campaignDetails?.description}
      </CampaignDescText>
      <Show when={!!campaignDetails?.tagline}>
        <Badge
          themeColor={campaignDetails?.colorTheme}
          text={campaignDetails?.tagline ?? ""}
        />
      </Show>
      <Show when={isMobile}>
        <Button
          buttonType="primary"
          label="Donate Now"
          type="button"
          style={{
            padding: "1.5rem",
            fontSize: "1rem",
            margin: "1rem 0 3rem 0",
            width: "95%",
          }}
          onClick={scrollToDonateDiv}
          themeColor={campaignDetails?.colorTheme}
        />
      </Show>
      <PoweredBy $isMobile={isMobile}>
        <Tag
          size="lg"
          colorScheme={"blackAlpha"}
          borderRadius="full"
          onClick={() => window.open("https://kissgives.com", "_blank")}
          style={{ cursor: "pointer", margin: "1rem" }}
        >
          <Avatar src={kissIcon} size="xs" ml={-1} mr={2} />
          <TagLabel>Powered by KISS Gives</TagLabel>
        </Tag>
      </PoweredBy>
    </>
  );

  const totalRaised = Math.round(
    (campaignDetails?.totalRaised ?? 0) + (campaignDetails?.bonus ?? 0)
  );

  const donateSection = (
    <>
      <Heading fontSize={"3rem"}>The Goal</Heading>
      <Show when={!!campaignDetails?.expires}>
        <CountdownTimer
          colorTheme={campaignDetails?.colorTheme ?? colors.gold}
          expirationUtc={String(campaignDetails?.expires)}
        />
      </Show>
      <ProgressContainer>
        <ProgressDetails>
          <LeftProgressDetails>
            <TotalRaisedText>{`$${formatNumber(
              Math.round(totalRaised / 100)
            )}`}</TotalRaisedText>
            <Show
              when={(campaignDetails?.totalContributors ?? 0) > 0 && !isMobile}
            >
              <ParticipantsText>{`${campaignDetails?.totalContributors} participants`}</ParticipantsText>
            </Show>
          </LeftProgressDetails>
          <GoalText>
            {`$${formatNumber(
              Math.round((campaignDetails?.goalAmount ?? 0) / 100)
            )}`}
          </GoalText>
        </ProgressDetails>
        <ProgressWrapper>
          <ProgressBar
            value={totalRaised}
            max={campaignDetails?.goalAmount ?? 0}
            color={campaignDetails?.colorTheme ?? colors.gold}
          />
        </ProgressWrapper>
        <Box p={5} width={"95%"} padding={"1rem 0"}>
          <RecentDonationsTicker
            messages={campaignDetails?.recents ?? []}
            themeColor={campaignDetails?.colorTheme}
          />
        </Box>
      </ProgressContainer>
      <Show when={!!campaignDetails?.bonus}>
        <BonusInfo
          $themeColor={campaignDetails?.colorTheme}
          $isMobile={isMobile}
        >
          <BonusInfoText
            $themeColor={campaignDetails?.colorTheme}
          >{`❤️ $${formatNumber(
            Math.round((campaignDetails?.bonus ?? 0) / 100)
          )} in sponsor matches!`}</BonusInfoText>
        </BonusInfo>
      </Show>
      <Divider
        orientation="horizontal"
        width={"80%"}
        margin={"10"}
        borderColor={colors.black + "50"}
      />
      <Heading size={"lg"}>Select Amount</Heading>
      <Tabs
        margin={3}
        variant="solid-rounded"
        onChange={(num) => setGiveInterval(num === 0 ? "one-time" : "monthly")}
      >
        <TabList>
          <Tab
            _selected={{
              bg: (campaignDetails?.colorTheme ?? colors.gold) + "95",
              color: colors.white,
            }}
          >
            One Time
          </Tab>
          <Tab
            _selected={{
              bg: (campaignDetails?.colorTheme ?? colors.gold) + "95",
              color: colors.white,
            }}
          >
            Monthly
          </Tab>
        </TabList>
      </Tabs>
      <CheckboxGroup>
        <CheckBoxContainer>
          <Checkbox
            isChecked={isCoveringFees}
            onChange={(e) => setIsCoveringFees(e.target.checked)}
            defaultChecked
          >
            Cover the processing fees
          </Checkbox>
          <Tooltip
            isOpen={isTooltipOpen1}
            label="Ensure that the organization gets 100% of the donated amount by covering the credit card processing fees."
            fontSize="md"
            placement="top"
          >
            <IconButton
              aria-label="More information"
              icon={<InfoOutlineIcon />}
              size="xs"
              variant="ghost"
              onClick={() => setIsTooltipOpen1(!isTooltipOpen1)}
              onBlur={() => setIsTooltipOpen1(false)}
              _hover={{ backgroundColor: "transparent" }}
              marginLeft={"1.5"}
            />
          </Tooltip>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <Checkbox
            isChecked={isTipping}
            onChange={(e) => setIsTipping(e.target.checked)}
            defaultChecked
          >
            Support our platform partner{" "}
          </Checkbox>
          <Select
            defaultValue={"10"}
            size="xs"
            onChange={(e) => setSelectedTipPercentage(Number(e.target.value))}
            maxWidth={"20"}
            style={{ margin: "0 0.25rem" }}
          >
            <option value="5">5%</option>
            <option value="10">10%</option>
            <option value="15">15%</option>
            <option value="20">20%</option>
          </Select>
          <Tooltip
            isOpen={isTooltipOpen2}
            label="The KISS Gives platform is free for our organization. Help them keep the lights on with an optional tip."
            fontSize="md"
            placement="top"
          >
            <IconButton
              aria-label="More information"
              icon={<InfoOutlineIcon />}
              size="xs"
              variant="ghost"
              onClick={() => setIsTooltipOpen2(!isTooltipOpen2)}
              onBlur={() => setIsTooltipOpen2(false)}
              _hover={{ backgroundColor: "transparent" }}
              marginLeft={"1.5"}
            />
          </Tooltip>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <Checkbox
            isChecked={giveAnon}
            onChange={(e) => setGiveAnon(e.target.checked)}
          >
            Give anonymously
          </Checkbox>
        </CheckBoxContainer>
      </CheckboxGroup>
      <Show
        when={!showOtherAmount}
        whenFalseContent={
          <OtherAmount
            themeColor={campaignDetails?.colorTheme ?? colors.gold}
            onSubmit={(amount: number) => handlePayment(amount)}
            onCancel={() => setShowOtherAmount(false)}
          />
        }
      >
        <AmountsContainer>
          {generatePaymentButtons([25, 50, 100, 250, 500, 0])}
        </AmountsContainer>
      </Show>
      <Divider
        orientation="horizontal"
        width={"80%"}
        margin={"10"}
        borderColor={colors.black + "50"}
      />
      <Heading style={{ marginBottom: "1rem" }} size={"lg"}>
        Spread The Word
      </Heading>
      <Button
        buttonType="secondary"
        label="Copy Share Link 🚀"
        type="button"
        onClick={handleShareClick}
      />
    </>
  );

  const redirect = (
    <>
      <Spinner size={"sm"} />
      <Text>You are being redirected to our payment provider.</Text>
    </>
  );

  const infoContent = (
    <Show
      when={!showSuccess}
      whenFalseContent={isMobile ? successMessage : campaignInfoSection}
    >
      {campaignInfoSection}
    </Show>
  );

  const donationContent = (
    <Show when={!gettingLink} whenFalseContent={redirect}>
      <Show when={!showSuccess} whenFalseContent={successMessage}>
        {donateSection}
      </Show>
    </Show>
  );

  return (
    <>
      {campaignDetails && (
        <SocialCard
          title={campaignDetails?.name ?? undefined}
          description={campaignDetails?.description ?? undefined}
          image={campaignDetails?.socialShareImage ?? undefined}
        />
      )}
      <Show
        when={
          !!campaignDetails?.alertTitle &&
          !!campaignDetails.alertDescription &&
          !!campaignDetails.alertImage
        }
      >
        <AlertMessage
          title={campaignDetails?.alertTitle as string}
          description={campaignDetails?.alertDescription as string}
          image={campaignDetails?.alertImage as string}
          buttonLabel={campaignDetails?.alertButtonLabel ?? undefined}
          themeColor={campaignDetails?.colorTheme}
          expiration={String(campaignDetails?.expires)}
        />
      </Show>
      <PageWrapper $isMobile={isMobile}>
        <Show
          when={!!campaignDetails}
          whenFalseContent={
            <SpinnerPage>
              <Spinner size={"xl"} />
            </SpinnerPage>
          }
        >
          <>
            <Organization
              onClick={() =>
                window.open(
                  campaignDetails?.organizationWebsite ?? "",
                  "_blank"
                )
              }
            >
              <Image src={campaignDetails?.organizationLogo} size="3rem" />
              <ClientName>{campaignDetails?.organization}</ClientName>
            </Organization>
            <InfoContainer
              $themeColor={campaignDetails?.colorTheme}
              $isMobile={isMobile}
            >
              {infoContent}
            </InfoContainer>
            <Show
              when={isMobile && showSuccess}
              whenFalseContent={
                <DonationContainer
                  $themeColor={campaignDetails?.colorTheme}
                  ref={donateDiv}
                  $isMobile={isMobile}
                >
                  {donationContent}
                </DonationContainer>
              }
            >
              <></>
            </Show>
          </>
        </Show>
      </PageWrapper>
    </>
  );
};
