import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { styled } from "styled-components";
import { Button } from "./Button";
import { CountdownTimer } from "./CountdownTimer";
import { colors } from "../colors";
import { Show } from "./Show";

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
`;

const FooterContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export type AlertMessageTypes = {
  title: string;
  description: string;
  image: string;
  buttonLabel?: string;
  themeColor?: string;
  expiration?: string;
};

export const AlertMessage = (props: AlertMessageTypes) => {
  const { title, description, image, buttonLabel, themeColor, expiration } =
    props;
  const { isOpen = true, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent m="auto">
            <HeaderImage src={image} />
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              style={{ paddingBottom: "0.5rem" }}
            >
              <FooterContentWrapper>
                {title}
                <Show when={!!expiration}>
                  <CountdownTimer
                    colorTheme={themeColor ?? colors.black}
                    expirationUtc={expiration!}
                    isInAlertMessage
                  />
                </Show>
              </FooterContentWrapper>
            </AlertDialogHeader>
            <AlertDialogBody>{description}</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                buttonType="primary"
                themeColor={themeColor}
                onClick={onClose}
                label={buttonLabel ?? "OK"}
              />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
