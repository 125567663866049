import axios from "axios";
import { buildAuthHeaders } from "../buildAuthHeaders";
import { rootUrl } from "../rootUrl";

type CreateUserResponse = {
  id: string;
};

const endpoint = `/user/create`;

export const postCreateUser = async (
  id: string,
  email: string,
  organization: string,
  ein: string,
  accessToken: string
): Promise<CreateUserResponse> => {
  const { data } = await axios.post<CreateUserResponse>(
    `${rootUrl}${endpoint}`,
    { id, email, organization, ein },
    buildAuthHeaders(accessToken)
  );
  return data;
};
