import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Heading, Input, Spinner, Text } from "@chakra-ui/react";
import { Button } from "../Button";
import { useShowToast } from "../../hooks/useShowToast";
import { Show } from "../Show";
import { colors } from "../../colors";
import stripeLogo from "../../assets/stripe-logo.png";
import icon from "../../assets/icon.svg";
import { getPaymentLinks } from "../../api/user/getPaymentLinks";
import { STRIPE_DASHBOARD } from "../../constants";
import { useStepper } from "../../hooks/useStepper";
import { Gold, Image } from "../Reusable";
import { patchUpdateUser } from "../../api/user/patchUpdateUser";
import { DragDrop, LogoFile } from "../DragDrop";
import { postUploadFile } from "../../api/user/postUploadFile";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: -4rem;
`;

const Guide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 35rem;
  min-width: 33rem;
  height: 35rem;
  border: 1px solid ${colors.black + "20"};
  background-color: ${colors.white};
  box-shadow: 0 0.25rem 1rem ${colors.black + 15};
  border-radius: 1rem;
  padding: 2rem;
  position: relative;
`;

const StepperWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 85%;
`;

const Logos = styled.div`
  display: flex;
  align-items: center;
`;

export const ConnectBank = () => {
  const { accessToken, onboardingResults, getOnboardingResults } = useAuth();
  const { showToast } = useShowToast();
  const [enteredWebsite, setEnteredWebsite] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const steps = [
    { title: "Enter website" },
    { title: "Upload logo" },
    { title: "Connect bank" },
  ];

  const calculateStepIndex = () => {
    return !onboardingResults.website
      ? 0
      : !onboardingResults.logo
      ? 1
      : !onboardingResults.charges
      ? 2
      : 3;
  };

  const { stepper, setActiveStep, activeStep } = useStepper({
    index: calculateStepIndex(),
    steps,
  });

  useEffect(() => {
    setActiveStep(calculateStepIndex());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingResults]);

  useEffect(() => {
    if (accessToken && !onboardingResults.stripeId) {
      getOnboardingResults(accessToken);
    }

    if (accessToken) getPaymentLinks(accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const handleConnection = () => {
    try {
      setIsProcessing(true);
      if (!onboardingResults.link) {
        showToast("Could not get a valid connection link!", "error");
        return;
      }

      window.location.href = onboardingResults.link;
    } catch (error) {
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSaveWebsite = async () => {
    if (accessToken) {
      setIsProcessing(true);
      if (!enteredWebsite.includes("https://")) {
        showToast('Please enter a valid url that includes "https://"', "error");
        setIsProcessing(false);
        return;
      }
      const res = await patchUpdateUser(
        { website: enteredWebsite ?? undefined },
        accessToken
      );
      if (res.id) showToast("Website saved successfully!", "success");
      setIsProcessing(false);
      setActiveStep(1);
    }
  };

  const handleLogoUpload = async (file: LogoFile) => {
    if (accessToken) {
      setIsProcessing(true);
      const res = await postUploadFile(
        {
          file: file.base64,
          type: "CLIENT_LOGO",
          mimeType: file.type,
          name: file.name,
        },
        accessToken
      );
      if (res.url) showToast("Logo uploaded successfully!", "success");

      setIsProcessing(false);
      setActiveStep(2);
    }
  };

  const needToConnect = (
    <>
      <Logos>
        <Image
          style={{ borderRadius: "50%", margin: "0 0.5rem 0 0" }}
          src={stripeLogo}
          alt="Stripe Logo"
          size={"3.5rem"}
        />
        <Heading
          fontSize={"xl"}
          color={colors.black + 30}
          mb={"0.5rem"}
          mr={"-0.75rem"}
        >
          {"----"}
        </Heading>
        <Image
          style={{ borderRadius: "50%", margin: "0 0 0.25rem 0.5rem" }}
          src={icon}
          alt="Stripe Logo"
          size={"5rem"}
        />
      </Logos>

      <Heading textAlign={"center"}>Connect your bank account</Heading>
      <Text textAlign={"center"} mb={"1rem"}>
        We use{" "}
        <a
          href="https://stripe.com/connect"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: colors.gold, fontWeight: "bold" }}
        >
          Stripe Connect
        </a>{" "}
        to deposit funds to your bank.
      </Text>
      <Button
        buttonType="primary"
        label="Connect With Stripe"
        isLoading={isProcessing || onboardingResults.details}
        onClick={handleConnection}
      />
      <Show when={onboardingResults.details && !onboardingResults.charges}>
        <Text
          textAlign={"center"}
          fontSize={"xs"}
          marginTop={3}
          color={colors.error}
        >
          Integration complete but payments are disabled. Log in to your{" "}
          <a
            style={{ color: colors.blueLink, textDecoration: "underline" }}
            href={STRIPE_DASHBOARD}
          >
            Stripe Account
          </a>{" "}
          and address any issues then refresh this page.
        </Text>
      </Show>
    </>
  );

  const connected = (
    <>
      <Heading textAlign={"center"}>✅</Heading>
      <Heading textAlign={"center"}>Account is connected</Heading>
      <Text>
        Questions? Let us know at <Gold>hello@kissgives.com</Gold>
      </Text>
    </>
  );

  const website = (
    <>
      <Heading textAlign={"center"}>Website</Heading>
      <Text textAlign={"center"} mb={"1rem"}>
        Enter the organizations's main website url
      </Text>
      <Input
        placeholder={"https://www.walkwithsally.org"}
        focusBorderColor={colors.gold + "60"}
        onChange={(e) => setEnteredWebsite(e.target.value)}
        type="text"
        width={"70%"}
        autoFocus
      />
      <Button
        buttonType="primary"
        label="Save and Continue"
        onClick={handleSaveWebsite}
        isLoading={isProcessing}
        disabled={!enteredWebsite}
        style={{ margin: "1rem" }}
      />
      <Show when={!!enteredWebsite}>
        <a href={enteredWebsite} target="_blank" rel={"noopener noreferrer"}>
          <Gold style={{ fontSize: "0.85rem" }}>Verify my link</Gold>
        </a>
      </Show>
    </>
  );

  const logo = (
    <Show when={!isProcessing} whenFalseContent={<Spinner />}>
      <Heading textAlign={"center"}>Logo</Heading>
      <Text mb={"1rem"}>
        Upload your organization's{" "}
        <span style={{ textDecoration: "underline" }}>square</span> logo
      </Text>
      <DragDrop onLogoChange={(logo) => handleLogoUpload(logo)} />
    </Show>
  );

  return (
    <Container>
      <Guide>
        <Show when={activeStep === 0}>{website}</Show>
        <Show when={activeStep === 1}>{logo}</Show>
        <Show when={activeStep === 2}>{needToConnect}</Show>
        <Show when={activeStep === 3}>{connected}</Show>
        <StepperWrapper>{stepper}</StepperWrapper>
      </Guide>
    </Container>
  );
};
