import axios from "axios";
import { rootUrl } from "../rootUrl";

export type GetCampaignResponse = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  clientId: string;
  logo: string;
  organization: string;
  organizationWebsite: string | null;
  organizationLogo: string;
  name: string;
  description: string;
  tagline?: string | null;
  socialShareImage?: string | null;
  goalAmount: number;
  totalRaised: number;
  totalContributors: number;
  bonus: number;
  expires?: Date | null;
  recents: string[];
  alertTitle?: string;
  alertDescription?: string;
  alertImage?: string;
  alertButtonLabel?: string;
  colorTheme?: string;
};

export const getCampaign = async (
  campaignId: string
): Promise<GetCampaignResponse> => {
  const endpoint = `/campaign/${campaignId}`;
  const { data } = await axios.get<GetCampaignResponse>(
    `${rootUrl}${endpoint}`
  );
  return data;
};
