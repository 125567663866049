import { styled } from "styled-components";
import { Heading, Text } from "@chakra-ui/react";

const Content = styled.div`
  margin-top: 3rem;
`;

export const Settings = () => {
  return (
    <Content>
      <Heading>Settings</Heading>
      <Text>🛠 Page is under active development</Text>
    </Content>
  );
};
