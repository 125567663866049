import { styled } from "styled-components";
import Typewriter from "typewriter-effect";
import { colors } from "../colors";
import { HeaderText } from "./Reusable";

const TypeText = styled(HeaderText)`
  color: ${colors.black};
  font-size: 3.5rem;
  font-weight: 700;
  width: 85%;
`;

export type TypeEffectProps = {
  onFinish: () => void;
};

export const TypeEffect = (props: TypeEffectProps) => {
  const { onFinish } = props;
  return (
    <TypeText>
      <Typewriter
        onInit={(typewriter) => {
          typewriter
            .pauseFor(1000)
            .typeString(
              `Fundraise without the <strong><span style="color: ${colors.gold};">noise</span></strong>.`
            )
            .pauseFor(1500)
            .deleteChars(6)
            .typeString(
              `<strong><span style="color: ${colors.gold};">headache</span></strong>.`
            )
            .pauseFor(1500)
            .deleteChars(9)
            .typeString(
              `<strong><span style="color: ${colors.gold};">cost</span></strong>.`
            )
            .pauseFor(1500)
            .deleteChars(20)
            .pauseFor(1500)
            .typeString(
              `with <strong><span style="color: ${colors.gold};">KISS</span></strong>.`
            )
            .callFunction(onFinish)
            .start();
        }}
      />
    </TypeText>
  );
};
