import styled from "styled-components";
import { colors } from "../colors";
import icon from "../assets/icon.svg";
import { Image } from "../components/Reusable";
import { useViewport } from "../hooks/useViewport";

const Container = styled.div<{ $isDesktop: boolean }>`
  font-family: "Helvetica Neue", sans-serif;
  margin: 1.25rem;
  margin: 0 auto;
  padding: ${(props) => (props.$isDesktop ? "3rem 12rem" : "3rem 2rem")};
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${colors.black};
`;

const SubTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 500;
  color: ${colors.black};
  margin-top: 1.25rem;
  text-transform: uppercase;
`;

const Paragraph = styled.p`
  font-size: 1.25rem;
  color: ${colors.black};
`;

const Strong = styled.strong`
  font-weight: bold;
`;

export const SmsPolicy = () => {
  const { isDesktop } = useViewport();
  return (
    <Container $isDesktop={isDesktop}>
      <Image src={icon} />
      <Title>SMS TEXT POLICY</Title>
      <Paragraph>Effective Date: November 7, 2023</Paragraph>
      <Paragraph>
        By participating in the KissGives Text Message Program, you agree to the
        following Terms of Service:
      </Paragraph>
      <SubTitle>1. Consent to Receive Text Messages</SubTitle>
      <Paragraph>
        By texting your campaign from +1(310) 896-4373, or other local/toll-free
        numbers used by KISS Gives, you consent that your contact list has
        agreed to receive one or more auto-dialed or prerecorded text messages
        at the phone number from which your campaign has provided.
      </Paragraph>

      <SubTitle>2. Disclaimer of Warranties</SubTitle>
      <Paragraph>
        We will not be liable for any delays in the receipt of any SMS messages
        as delivery is subject to effective transmission from your mobile
        service operator.{" "}
        <Strong>
          SERVICES ARE PROVIDED ON AN “AS IS” BASIS, AND WE MAKE NO WARRANTY,
          EXPRESS OR IMPLIED, AND ALL WARRANTIES, INCLUDING IMPLIED WARRANTIES
          OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, ARE HEREBY
          EXPRESSLY DISCLAIMED.
        </Strong>
      </Paragraph>

      <SubTitle>3. Data Collection and Use</SubTitle>
      <Paragraph>
        Data obtained from you in connection with this SMS service may include
        your cell phone number, your carrier’s name, and the date, time, and
        content of your messages, as well as other information that you provide.
        We may use this information to contact you, or your contact list, to
        provide the services you request from us.
      </Paragraph>

      <SubTitle>4. Consent for Promotional Messages</SubTitle>
      <Paragraph>
        By subscribing, you consent to receive, and/or send text messages,
        including informational alerts, promotional messages, and updates. Your
        consent to receive, and/or send, promotional messages is voluntary; you
        do not need to consent to receive promotional messages in order to
        participate in the Services.
      </Paragraph>

      <SubTitle>5. Right to Change or Terminate Service</SubTitle>
      <Paragraph>
        By subscribing or otherwise using the SMS service, you acknowledge and
        agree that we will have the right to change and/or terminate the service
        at any time, with or without cause and/or advance notice.
      </Paragraph>

      <SubTitle>6. Opt-Out Instructions</SubTitle>
      <Paragraph>
        To cancel your SMS service subscription, you, or your contact list, may
        text STOP to +1(310) 896-4373, or other local/toll-free numbers used by
        KISS Gives, in reply to a text message you receive. You may receive a
        subsequent message confirming your opt-out request.
      </Paragraph>
      <SubTitle>7. Support</SubTitle>
      <Paragraph>
        For additional help, text HELP to +1(310) 896-4373, or other
        local/toll-free numbers used by KISS Gives, in reply to a text message
        you receive. You can also contact our support team at help@kissgives.com
      </Paragraph>
      <SubTitle>8. Message and Data Rates</SubTitle>
      <Paragraph>
        Message and Data Rates may apply, depending on your mobile service
        provider and plan.
      </Paragraph>
      <SubTitle>9. Carrier Liability</SubTitle>
      <Paragraph>
        T-Mobile® is not liable for delayed or undelivered messages.
      </Paragraph>
      <SubTitle>10. Participating Carriers</SubTitle>
      <Paragraph>
        United States Participating Carriers include AT&amp;T, T-Mobile®,
        Verizon Wireless, Sprint, Boost, U.S. Cellular®, MetroPCS®, InterOp,
        Cellcom, C Spire Wireless, Cricket, Virgin Mobile, and others.
      </Paragraph>
      <SubTitle>11. Program Availability</SubTitle>
      <Paragraph>
        Currently, the KissGives Text Message Program is only available to
        residents of the U.S. You understand and acknowledge that you may not
        sign up for, access, or attempt to access or use the Text Message
        Program from countries outside of the U.S. You agree to abide by U.S.
        and other applicable export control laws and not to transfer, by
        electronic transmission or otherwise, any content or software subject to
        restrictions under such laws to a national destination or person
        prohibited under such laws.
      </Paragraph>
    </Container>
  );
};
