import styled from "styled-components";
import createAccount from "../assets/create-account-icon.svg";
import secure from "../assets/secure-icon.svg";
import message from "../assets/message-icon.svg";
import data from "../assets/data-icon.svg";
import { HeaderText, SubTitle, fadeIn } from "./Reusable";
import { colors } from "../colors";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0.5rem;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Icon = styled.img`
  width: 3rem;
  height: 3rem;
  margin: 0.5rem;
`;

const TitleText = styled(HeaderText)`
  font-size: 1.5rem;
  color: ${colors.gold};
  text-align: left;
  margin: 0;
`;

const DescText = styled(SubTitle)`
  color: ${colors.black};
  text-align: left;
  font-size: 0.85rem;
  animation: ${fadeIn} 1s ease-in-out;
  margin: 0;
  max-width: 20rem;
`;

type IconType = "create-account" | "secure" | "message" | "data";

export type HowItWorksInfoProps = {
  icon: IconType;
  title: string;
  description: string;
};

export const HowItWorksInfoText = (props: HowItWorksInfoProps) => {
  const { description, icon, title } = props;
  const selectedSvg =
    icon === "create-account"
      ? createAccount
      : icon === "secure"
      ? secure
      : icon === "data"
      ? data
      : icon === "message"
      ? message
      : "";
  return (
    <Container>
      <Icon src={selectedSvg} />
      <TextWrapper>
        <TitleText>{title}</TitleText>
        <DescText>{description}</DescText>
      </TextWrapper>
    </Container>
  );
};
